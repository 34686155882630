import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SocialVerce, VenueAttributesDTO, VenueDTO } from '../storeModels';
import { httpClient } from '../../services/httpClient/httpClient';
import { getApiUrlForId, AccountEndpoints } from '../../api/endpoints';
import { OrganizationAPIModel, VenueAPIModel, VenueProperties } from '../../api/models/venue';
import { ClientsTypes } from '../../api/models/common';
import { getSubdomain } from '../../services/utilities';

interface VenueSlice {
  accountAttributes: VenueAttributesDTO;
  account: VenueDTO;
  address: AccountAddress;
  defaultSv: SocialVerce | null;
}

interface AccountAddress {
  address: string;
  city: string;
  state: string;
  zip: string;
}

export const initialVenueState: VenueSlice = {
  accountAttributes: {
    properties: {},
    error: false,
    isLoading: true,
    lastUpdated: new Date().toISOString(),
  },
  account: {
    clientType: ClientsTypes.FUNDRAISING,
    id: '',
    logo: '',
    name: '',
    orgId: '',
    subdomain: '',
    error: false,
    isLoading: true,
    lastUpdated: new Date().toISOString(),
    scaleoOfferId: null,
    businessAddress: '',
    websiteUrl: '',
    active: true,
  },
  address: {
    address: '',
    city: '',
    state: '',
    zip: '',
  },
  defaultSv: null,
};

export const getVenue = createAsyncThunk('venue/getVenue', async (_options: undefined, { rejectWithValue }) => {
  //can be replaced with particular venue id
  const subdomain = getSubdomain();

  try {
    return await httpClient.get<undefined, VenueAPIModel>({
      url: getApiUrlForId(AccountEndpoints.Account, subdomain),
      requiresToken: false,
    });
  } catch (error) {
    // @ts-ignore
    return rejectWithValue(error.response.data.message);
  }
});

export const getAccountAddress = createAsyncThunk(
  'account/getAddress',
  async ({ accountId }: { accountId: string }, { rejectWithValue }) => {
    try {
      return await httpClient.get<undefined, AccountAddress>({
        url: getApiUrlForId(AccountEndpoints.GetAccountAddress, accountId),
        requiresToken: false,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getOrganizationById = async ({ id }: { id: string }): Promise<OrganizationAPIModel> => {
  try {
    return await httpClient.get({
      url: getApiUrlForId(AccountEndpoints.GetOrganizationById, id),
      requiresToken: true,
    });
  } catch (error) {
    throw error;
  }
};

export const getProperties = createAsyncThunk('venue/getProperties', async (_options: string, { rejectWithValue }) => {
  try {
    return await httpClient.get<undefined, VenueProperties>({
      url: getApiUrlForId(AccountEndpoints.GetAccountProperties, _options),
      requiresToken: false,
    });
  } catch (error) {
    // @ts-ignore
    return rejectWithValue(error.response.data.message);
  }
});

export const getAccountDefaultSv = createAsyncThunk(
  'account/getDefaultSv',
  async ({ accountId }: { accountId: string }, { rejectWithValue }) => {
    try {
      return await httpClient.get<undefined, SocialVerce>({
        url: getApiUrlForId(AccountEndpoints.GetDefaultSv, accountId),
        requiresToken: false,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

const accountSlice = createSlice({
  name: 'venue',
  initialState: initialVenueState,
  reducers: {
    reset: () => initialVenueState,
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getVenue.rejected, (state) => {
      state.account.error = true;
      state.account.isLoading = false;
    });
    reducersBuilder.addCase(getVenue.pending, (state) => {
      state.account.isLoading = true;
      state.account.error = false;
    });
    reducersBuilder.addCase(getVenue.fulfilled, (state, { payload }) => {
      state.account.isLoading = false;
      state.account.error = false;
      state.account.id = payload.id;
      state.account.name = payload.name;
      state.account.orgId = payload.orgId;
      state.account.logo = payload.logo;
      state.account.subdomain = payload.subdomain;
      state.account.scaleoOfferId = payload.scaleoOfferId;
      state.account.clientType = payload.clientType;
      state.account.active = payload.active;
    });
    reducersBuilder.addCase(getProperties.rejected, (state) => {
      state.accountAttributes.error = true;
      state.accountAttributes.isLoading = false;
    });
    reducersBuilder.addCase(getProperties.pending, (state) => {
      state.accountAttributes.isLoading = true;
      state.accountAttributes.error = false;
    });
    reducersBuilder.addCase(getProperties.fulfilled, (state, { payload }) => {
      state.accountAttributes.isLoading = false;
      state.accountAttributes.error = false;
      state.accountAttributes.lastUpdated = new Date().toISOString();
      state.accountAttributes.properties = payload;
    });

    reducersBuilder.addCase(getAccountAddress.rejected, (state) => {
      state.account.error = true;
      state.accountAttributes.isLoading = false;
    });
    reducersBuilder.addCase(getAccountAddress.pending, (state) => {
      state.accountAttributes.isLoading = true;
      state.accountAttributes.error = false;
    });
    reducersBuilder.addCase(getAccountAddress.fulfilled, (state, { payload }) => {
      state.address = payload;
    });

    reducersBuilder.addCase(getAccountDefaultSv.rejected, (state) => {
      state.account.error = true;
      state.accountAttributes.isLoading = false;
    });
    reducersBuilder.addCase(getAccountDefaultSv.pending, (state) => {
      state.accountAttributes.isLoading = true;
      state.accountAttributes.error = false;
    });
    reducersBuilder.addCase(getAccountDefaultSv.fulfilled, (state, { payload }) => {
      state.defaultSv = payload;
    });
  },
});

export const { reset } = accountSlice.actions;
export default accountSlice.reducer;
